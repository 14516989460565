// extracted by mini-css-extract-plugin
export var indexTopHeader1 = "index-module--indexTopHeader1--1Jo31";
export var indexTopHeader2 = "index-module--indexTopHeader2--3vPnn";
export var indexInfo1 = "index-module--indexInfo1--3haE-";
export var indexInfo12 = "index-module--indexInfo12--3RIvs";
export var indexCalendarIcon = "index-module--indexCalendarIcon--invHK";
export var indexInfo1Img = "index-module--indexInfo1Img--2TpFW";
export var indexInfo1Text = "index-module--indexInfo1Text--1vYX2";
export var indexInfo1Triangle = "index-module--indexInfo1Triangle--1EiOa";
export var indexInfo1TriangleIcon = "index-module--indexInfo1TriangleIcon--1BQ76";
export var indexInfo1TriangleIconUp = "index-module--indexInfo1TriangleIconUp--1NTNC";
export var indexInfo1Extended = "index-module--indexInfo1Extended--sTXFf";
export var indexInfo1ExtendedResponsive = "index-module--indexInfo1ExtendedResponsive--2AEgF";
export var indexInfo2 = "index-module--indexInfo2--2GfIQ";
export var indexInfo2Img = "index-module--indexInfo2Img--2gkQT";
export var indexMaskIcon = "index-module--indexMaskIcon--EYww9";
export var indexInfo2Text = "index-module--indexInfo2Text--9eClP";
export var indexTopImage = "index-module--indexTopImage--DOUU_";
export var indexMain = "index-module--indexMain--2YZD-";
export var indexMainResponsive = "index-module--indexMainResponsive--1M2_r";
export var indexMainImgDiv = "index-module--indexMainImgDiv--3f3g8";
export var indexMainIMG = "index-module--indexMainIMG--1GlCG";
export var indexMainBG = "index-module--indexMainBG--bhyIS";
export var indexMainText = "index-module--indexMainText--3s-pV";
export var indexZitat = "index-module--indexZitat--6_F3n";
export var indexZitatImg = "index-module--indexZitatImg--1xGsZ";
export var indexZitatText = "index-module--indexZitatText--1M86U";
export var indexTop = "index-module--indexTop--3lB2n";
export var indexMiddleText = "index-module--indexMiddleText--r4oIw";
export var indexTopHeaderDiv = "index-module--indexTopHeaderDiv--Rn4B0";