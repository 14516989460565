import Layout from "../components/Layout"
import Navigation from "../components/Navigation"
import * as styles from "../assets/css/index.module.css"
import React, { useState } from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { getImage } from "gatsby-plugin-image"
import "animate.css"

const HomePage = ({ data }) => {
  const { dieterkrametter, zitat, calendar, triangle } = data
  const [show, setShow] = useState(true)

  return (
    <Layout>
      <div className={styles.indexTop}>
        <div className={styles.indexTopImage}>
          <GatsbyImage
            image={getImage(dieterkrametter)}
            title={dieterkrametter.title}
            alt="Dr. Dieter Krametter"
          />
        </div>
        <div className={styles.indexTopHeaderDiv}>
          <p className={styles.indexTopHeader1}>Neurologische Praxis</p>
          <p className={styles.indexTopHeader2}>Dr. Krametter</p>
          <div className="animate__animated animate__pulse animate__fast animate__repeat-3">
            <div
              className={styles.indexInfo1}
              onClick={() => setShow(s => !s)}
              onKeyDown={() => setShow(s => !s)}
              role="button"
              tabIndex="0"
            >
              <div className={styles.indexInfo1Img}>
                <div className={styles.indexCalendarIcon}>
                  <GatsbyImage
                    image={getImage(calendar)}
                    title={calendar.title}
                    alt="Öffnungszeiten"
                  />
                </div>
              </div>
              <div className={styles.indexInfo1Text}>
                Termine nach Voranmeldung
              </div>
              <div className={styles.indexInfo1Triangle}>
                <div>
                  <div
                    className={styles.indexInfo1TriangleIcon}
                    style={{ display: show ? "block" : "none" }}
                  >
                    <GatsbyImage
                      image={getImage(triangle)}
                      title={triangle.title}
                      alt="Öffnungszeiten"
                    />
                  </div>

                  <div
                    className={styles.indexInfo1TriangleIconUp}
                    style={{ display: show ? "none" : "block" }}
                  >
                    <GatsbyImage
                      image={getImage(triangle)}
                      title={triangle.title}
                      alt="Öffnungszeiten"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.indexInfo12}>
            <div className={styles.indexInfo1Img}>
              <div className={styles.indexCalendarIcon}>
                <GatsbyImage
                  image={getImage(calendar)}
                  title={calendar.title}
                  alt="Öffnungszeiten"
                />
              </div>
            </div>
            <div className={styles.indexInfo1Text}>
              Termine nach Voranmeldung
            </div>
            <div className={styles.indexInfo1Triangle}>
              <div>
                <GatsbyImage
                  image={getImage(triangle)}
                  title={triangle.title}
                  alt="Öffnungszeiten"
                />
                <GatsbyImage
                  image={getImage(triangle)}
                  title={triangle.title}
                  alt="Öffnungszeiten"
                />
              </div>
            </div>
          </div>

          <div
            className={styles.indexInfo1Extended}
            style={{ display: show ? "none" : "block" }}
          >
            <ul>
              <li>Montag: 8:00 - 11:00</li>
              <li>Dienstag: 8:00 - 11:00</li>
              <li>Mittwoch: 8:00 - 11:00</li>
              <li>Donnerstag: 14:00 - 16:00</li>
              <li>Freitag: 8:00 - 11:00</li>
              <p>und nach Vereinbarung</p>
              <h4>
                <a href="tel:03172-89052">Telefon 03172-89052</a>
              </h4>
            </ul>
          </div>
          <div className={styles.indexInfo1ExtendedResponsive}>
            <ul>
              <li>Montag: 8:00 - 11:00</li>
              <li>Dienstag: 8:00 - 11:00</li>
              <li>Mittwoch: 8:00 - 11:00</li>
              <li>Donnerstag: 14:00 - 16:00</li>
              <li>Freitag: 8:00 - 11:00</li>
              <p>und nach Vereinbarung</p>
              <h4>
                <a href="tel:03172-89052">Telefon 03172-89052</a>
              </h4>
            </ul>
          </div>

        </div>
      </div>

      <div className={styles.indexMain}>
        <div className={styles.indexMainImgDiv}>
          <div className={styles.indexMainIMG}>
            <GatsbyImage
              image={getImage(dieterkrametter)}
              alt="Dr. Dieter Krametter"
              title={dieterkrametter.title}
            />
          </div>
          <div className={styles.indexMainText}>
            <p>
              Als Facharzt für Neurologie beschäftige ich mich mit der Diagnose
              und Behandlung von Erkrankungen des zentralen und peripheren
              (äußeren) Nervensystems.
            </p>
            <p>
              Die Praxis liegt im MedZentrum Weiz, behindertengerecht
              erreichbar.
            </p>
            <p>Es bestehen Verträge mit allen Krankenkassen.</p>
            <p>
              Die Nähe zu Ärzten anderer Fachrichtungen im MedZentrum sowie im
              benachbarten A-Z Ärztezentrum Weiz stellt einen großen Vorteil für
              die vernetze Behandlung unserer Patienten dar.
            </p>
          </div>
        </div>
        <div className={styles.indexMainBG}></div>
      </div>
      <div className={styles.indexMainResponsive}>
        <div className={styles.indexMainText}>
          <p>
            Als Facharzt für Neurologie beschäftige ich mich mit der Diagnose
            und Behandlung von Erkrankungen des zentralen und peripheren
            (äußeren) Nervensystems.
          </p>
          <p>
            Die Praxis liegt im MedZentrum Weiz, behindertengerecht erreichbar.
          </p>
          <p>Es bestehen Verträge mit allen Krankenkassen.</p>
          <p>
            Die Nähe zu Ärzten anderer Fachrichtungen im MedZentrum sowie im
            benachbarten A-Z Ärztezentrum Weiz stellt einen großen Vorteil für
            die vernetze Behandlung unserer Patienten dar.
          </p>
        </div>
      </div>

      <div className={styles.indexZitat}>
        <div className={styles.indexZitatImg}>
          <GatsbyImage
            image={getImage(zitat)}
            title={zitat.title}
            alt="Arthur Schopenhauer Zitat"
          />
        </div>
        <div className={styles.indexZitatText}>
          <p>
            "Gesundheit ist zwar nicht alles - aber ohne Gesundheit ist alles
            nichts."
          </p>
          <h4>Arthur Schopenhauer</h4>
        </div>
      </div>

      <Navigation></Navigation>
    </Layout>
  )
}

export default HomePage

export const pageQuery = graphql`
  query IndexQuery {
    dieterkrametter: contentfulAsset(
      contentful_id: { eq: "7srMWLpHk1pXwVtTlf8ygl" }
    ) {
      gatsbyImageData(quality: 100)
      title
      description
    }
    zitat: contentfulAsset(contentful_id: { eq: "49DIjRpB2BtvIB46Fxp684" }) {
      gatsbyImageData(quality: 100)
      title
      description
    }
    calendar: contentfulAsset(contentful_id: { eq: "4vlrIpeB2zVtiuJyJBbKLu" }) {
      gatsbyImageData(quality: 100)
      title
      description
    }
    triangle: contentfulAsset(contentful_id: { eq: "7qX0sqqnXKFVDc5KIEOsw7" }) {
      gatsbyImageData(quality: 100)
      title
      description
    }
  }
`
